"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CollectedWebsocketCloseCodes = void 0;
const frontend_websocket_close_codes_enum_1 = require("./frontend-websocket-close-codes.enum");
const internal_websocket_close_codes_enum_1 = require("./internal-websocket-close-codes.enum");
exports.CollectedWebsocketCloseCodes = {
  ...frontend_websocket_close_codes_enum_1.FrontendWebsocketCloseCodes,
  ...internal_websocket_close_codes_enum_1.InternalWebsocketCloseCodes
};
